import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';
//import { EnvironmentInterface } from '@dialog-eservices-enablement/angular-components';
import { EnvironmentsInterface} from './core/models/environments.interface';
import { ENVIRONMENT } from './core/models/environment.token';
import { projectDetails, projectLabels } from './features/project/project-models';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private headerOptions: object;
  private headerOptionsLabel:object;
  private postheaderOptions: object;
  private keyId: string;
  private baseUrl: string;
  // public staticLabels: Array<object>;
  // public projectData: object;
  public projectData: projectDetails = new projectDetails();
  // public detailsData: object;
  private dataLabel2= new BehaviorSubject<any>(null);
  public data$ =this.dataLabel2.asObservable();
  // public isNoData = false;
  private dataDetails= new BehaviorSubject<any>(null);
  public detailsData$ =this.dataDetails.asObservable();
  private langState = new BehaviorSubject('English');
  langState$ = this.langState.asObservable();
  // public projectData: object;
  // drupApiUrl = 'https://cep-test-drupal.roche.com';
  // drupKey= '2022cf542f0805933dbd236f500db4fa';

  public constructor(@Inject(ENVIRONMENT) private environment: EnvironmentsInterface, private http: HttpClient, private authStoreService: AuthStoreService) {
    //this.domainSetUp();
    this.headerSetUp();
  }

  // public domainSetUp(): void {
  //   const domainUrl = window.location.hostname;

  //   switch (domainUrl) {
  //     case 'cep-angular-dev.roche.com':
  //       this.keyId = 'c85936d6515d10fe8f0e51b711bde96d';
  //       this.baseUrl = 'https://cep-drupal-dev.roche.com';
  //       break;
  //     case 'cep-angular-test.roche.com':
  //       this.keyId = '3f536f00228ca29b5792dce0b49cd9ad';
  //       this.baseUrl = 'https://cep-drupal-test.roche.com';
  //       break;
  //     case 'cep-angular.roche.com':
  //         this.keyId = '4a7d6f6a24644a5f033d80fa8d7c70e6';
  //         this.baseUrl = 'https://cep-drupal.roche.com';
  //         break;
  //     case 'localhost':
  //       this.keyId = 'c85936d6515d10fe8f0e51b711bde96d';
  //       this.baseUrl = 'https://cep-drupal-test.roche.com';
  //       break;
  //   }
  // }

  public headerSetUp(): void {
    
    const emailId = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.email : '';
    
    this.headerOptions = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: localStorage.getItem('languageCode') || 'en', email: emailId}
    };

    this.postheaderOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { _format: 'json', langcode: localStorage.getItem('languageCode') || 'en', email: emailId }
    };

    this.headerOptionsLabel = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: localStorage.getItem('languageCode') || 'en', email: emailId}
    };
  }
  public getApi(url: string, event_id?: string , country?: string): Observable<any> {
    
    const baseUrl = this.environment.drupApiUrl + url;
    
    if(country){
      this.headerOptions['params'].country = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.countryCode : '';
    }
    if (event_id) {
      this.headerOptions['params'].event_id = event_id;
    }
     return this.http.get(baseUrl, this.headerOptions)
  }

  public getApi2(url: string, event_id?: string , country?: string, langLabel?: string) {

    const emailId = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.email : '';
    // event_id = '718';
    this.headerOptions = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: langLabel, email: emailId}
    };
    const baseUrl = this.environment.drupApiUrl + url;
    
    if(country){
      this.headerOptions['params'].country = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.countryCode : '';
    }
    if (event_id) {
      this.headerOptions['params'].event_id = event_id;
    }

    this.http.get(baseUrl, this.headerOptions).subscribe(res =>{
      // console.log("res----", res.length);
      // if (Array.isArray(res)) {
      //   console.log(res.length);  
        // if(res.length == 0) {
        //    this.headerOptions = {
        //     headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
        //     params: { _format: 'json', langcode: 'en', email: emailId}
        //   };
        //   if(country){
        //     this.headerOptions['params'].country = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.countryCode : '';
        //   }
        //   if (event_id) {
        //     this.headerOptions['params'].event_id = event_id;
        //   }
        //   this.http.get(baseUrl, this.headerOptions).subscribe(res =>{
        //     this.dataDetails.next(res);
        //   })
        // } else {
          this.dataDetails.next(res);
        // }
      // }
      
     });
    //  return this.http.get(baseUrl, this.headerOptions)
  }
 

  public postApi(url: string, body: any, event_id?: string): Observable<any> {
    const baseUrl = this.environment.drupApiUrl + url;
    debugger;
    if (event_id) {
      this.postheaderOptions['params'].event_id = event_id;
    }
    return this.http.post(baseUrl, body, this.postheaderOptions);
  }

  public getProjectLabel(url: string): Observable<any> {
    const baseUrl = this.environment.drupApiUrl + url;

    return this.http.get(baseUrl, this.headerOptionsLabel);
  }

  getProjectLabel2(url: string, langLabel) {
    const baseUrl = this.environment.drupApiUrl + url;
    const emailId = this.authStoreService.isLoggedIn ? this.authStoreService.userProfile.email : '';

    this.headerOptionsLabel = {
      headers: { 'Content-Type': 'application/json', 'api-key': this.environment.drupKey },
      params: { _format: 'json', langcode: langLabel, email: emailId}
    };

     this.http.get(baseUrl, this.headerOptionsLabel).subscribe(res =>{
      this.dataLabel2.next(res);
     })
  }
  updateLanguage(language): void {
    this.langState.next(language);
  }

}

import { LocationStrategy } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AuthStoreService } from '@dialog-eservices-enablement/angular-components';

import { CookieService } from 'ngx-cookie-service';
import { AppService } from 'projects/dialog/src/app/app.service';
import { Observable } from 'rxjs';
// TODO Rename and put in its own module
import { FormControl } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { projectDetails, projectLabels } from '../../../project/project-models';
// import { ProjectTitleAndDetailsComponent } from '../../../project/project-title-and-details/project-title-and-details.component';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, MatSelectModule, MatTooltipModule, MatMenuModule, MatButtonModule]
})
export class LanguageSwitcherModule {}
@Component({
  selector: 'dl-angular-boilerplate',
  templateUrl: './angular-boilerplate.component.html',
  styleUrls: ['./angular-boilerplate.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AngularBoilerplateComponent implements OnInit {
  selectFormControl = new FormControl();
  public staticLabels: projectLabels = new projectLabels();
  public projectData: projectDetails = new projectDetails();
  public cookieValue: string;
  public userLsIdToken: string;
  public userLsAccessToken: string;
  public userLsUserdata: string;
  public isLoggedIn = this.authStoreService.isLoggedIn;
  public headerResponse: Observable<any>;
  public headerTitle;
  public label;
  public changedName = 'English';
  @Input() public event_id: any;
  public displayedLanguages=[{label:'English'}, {label:'Spanish'}, {label:'Portuguese'}];
  public constructor(private location: LocationStrategy, private authStoreService: AuthStoreService, private changeDetectorRef: ChangeDetectorRef, private appService: AppService, private cookieService: CookieService, private router: Router) { }
  public ngOnInit(): void {
    this.preventBackButton();
    window.scrollTo(0, 0);


    const apiUrl = '/api/roche-dia-it-header';
    this.headerResponse = this.appService.getApi(apiUrl);

    this.headerResponse.subscribe(res => {
      this.headerTitle = res[0];
      //this.label = res[0].roche_diagnostics_label;
      this.changeDetectorRef.detectChanges();
    });



    const afterSSOProjectId = localStorage.getItem('comingDirectFromSSOLogin');

    if (afterSSOProjectId != null) {
      this.router.navigate(['/project'], { queryParams: { event_id: afterSSOProjectId } });
      localStorage.removeItem('comingDirectFromSSOLogin');
    }

    const afterSuccessfulSSOProjectId = localStorage.getItem('afterSuccessfullSSOLogin');

    if (afterSuccessfulSSOProjectId != null) {
      this.router.navigate(['/project'], { queryParams: { event_id: afterSuccessfulSSOProjectId } });
      localStorage.removeItem('afterSuccessfullSSOLogin');
      localStorage.removeItem('comingDirectFromSSOLogin');
    }
    this.appService.langState$.subscribe(langState => {
      this.changedName = langState;
      this.changeDetectorRef.detectChanges();
    });

  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, location.href);
    })
    window.scrollTo(0, 0);
  }
  setLanguage (language) {

    let langLabel = 'en';
    let event_id = localStorage.getItem("event_id");
    var staticLabelApi = '/api/open-project/label';
   var projectDetailsApi = '/api/open-projects/details';
    
    if(language.label == "Spanish") {
      langLabel = 'es';
    }
    else if(language.label == "Portuguese") {
      langLabel = 'pt-br';
    } 
    localStorage.setItem("langLabel", langLabel);
    this.changedName = language.label;
    this.appService.getProjectLabel2(staticLabelApi,langLabel) 
    this.appService.getApi2(projectDetailsApi, event_id, "true", langLabel);
    this.changeDetectorRef.detectChanges();
  }


  
  routeT0Dashboard() {
    //this.router.navigateByUrl('/app-enabler');
    this.router.navigate(['/app-enabler'], { queryParams: { props: 'appLogo' } });
  }
}

import { LocationStrategy } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { projectLabels, projectDetails, faq } from '../project-models'
import { MatAccordion } from '@angular/material/expansion';
import { exit } from 'process';

@Component({
  selector: 'dl-project-title-and-details',
  templateUrl: './project-title-and-details.component.html',
  styleUrls: ['./project-title-and-details.component.scss']
})
export class ProjectTitleAndDetailsComponent implements OnInit, AfterViewInit {
  // @Input() public projectData: any;
  // @Input() public projectLabels: any;
  @Input() public event_id: any;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public staticLabels: projectLabels = new projectLabels();
  public projectData: projectDetails = new projectDetails();
  public flag1: boolean = false;
  public flag2: boolean = false;
  public flag3: boolean = false;
  public myArray: any = "";
  public detailsApiResponse : any;
  response: any;
  public faqArray: any = [];
  faqArrayLength: any;
  dd: any = "30 September, 2022";
  dateFormating: any;
  panelOpenState = false;
  @ViewChild('compen') compen2: ElementRef;

  constructor(private location: LocationStrategy, private router: Router, public appService: AppService, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {

  }


  preventBackButton() {
    history.pushState(null, null, location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, location.href);
      window.scroll(0, 0);
    })
    window.scroll(0, 0);
  }


  ngOnInit(): void {

    //encyption console.log(btoa("175"));
    //decryption console.log(atob("MTc1"));

    this.preventBackButton();
    window.scroll(0, 0);
    if (window.location.href.includes('event_id=')) {
      const index = window.location.href.indexOf('event_id');
      this.event_id = window.location.href.substring(index + 'event_id='.length, index + 'event_id='.length + 7);
      localStorage.setItem("event_id", this.event_id);
    }
    if(this.event_id) {
      localStorage.setItem("event_id", this.event_id);
    } else {
      if (window.location.href.includes('event_id=')) {
        const index = window.location.href.indexOf('event_id');
        this.event_id = window.location.href.substring(index + 'event_id='.length, index + 'event_id='.length + 7);
        localStorage.setItem("event_id", this.event_id);
      }
    }
    var staticLabelApi = '/api/open-project/label';
    var projectDetailsApi = '/api/open-projects/details';
    let langLabel;
    if(localStorage.getItem("langLabel")){
       langLabel = localStorage.getItem("langLabel");
    } else {
      langLabel = 'en';
    }
    
    this.appService.getProjectLabel2(staticLabelApi, langLabel);
    this.appService.data$.subscribe(data => {
      this.response = data;
      if(data) {
          this.labelApiResponse(data)
      }
    })
    this.appService.getApi2(projectDetailsApi,this.event_id, "true", langLabel);
    this.appService.detailsData$.subscribe(res => {
      if ( (res == null || res.length == 0) && !this.response) {
        this.flag3 = true;
        this.changeDetectorRef.detectChanges();
      } else if(res == null || res.length == 0) {
        //this.appService.getProjectLabel2(staticLabelApi, 'en');
        this.appService.getApi2(projectDetailsApi,this.event_id, "true", 'en');
        //this.appService.updateLanguage('English');
      } else if(res.length != 0){
        this.detailsApiResponseFunction(res);
      }
    });
    window.scroll(0, 0);
    window.onbeforeunload = function (e) {
      localStorage.removeItem('langLabel');
      };
  }
  detailsApiResponseFunction(res){
    if ( (res == null || res.length == 0) && !this.response) {
      this.flag3 = true;
      this.changeDetectorRef.detectChanges();
    }
    else {
      this.flag3 = false;
      this.detailsApiResponse = res;
      this.dateFormating = '';
      this.projectData.activity_schedule = res[0].activity_schedule;
      this.projectData.event_description = res[0].event_description;
      this.projectData.event_image_url = res[0].event_image_url;
      this.projectData.event_register_status = res[0].event_register_status;
      this.projectData.expected_time_frame_for_the_acti = res[0].expected_time_frame_for_the_acti;
      this.projectData.incentive = res[0].incentive;
      this.projectData.language = res[0].language;
      this.projectData.nid = res[0].nid;
      this.projectData.participating_study = res[0].participating_study;
      this.projectData.session_duration = res[0].session_duration;
      this.projectData.title = res[0].title;
      this.projectData.what_kind_of_activities_are_plan = res[0].what_kind_of_activities_are_plan;
      this.projectData.roles = res[0].roles;
      this.projectData.faq = res[0].faq;
      this.projectData.customer_benefit_2 = res[0].customer_benefit_2;
      this.projectData.customer_benefit_2_image = res[0].customer_benefit_2_image;
      this.projectData.customer_benefit_1 = res[0].customer_benefit_1;
      this.projectData.customer_benefit_1_image = res[0].customer_benefit_1_image;
      this.projectData.compensation_details = res[0].compensation_details;
      this.projectData.compensation_description = res[0].compensation_description;
      this.projectData.compensation_image = res[0].compensation_image;
      this.projectData.compensation_eligible = res[0].compensation_eligible;
      this.projectData.roche_signature = res[0].roche_signature;
      this.projectData.project_date = res[0].project_date;
      this.projectData.project_availability_for_country = res[0].project_availability_for_country;
      // this.appService.projectData = appDetailsData[0];

      if (this.projectData.faq = this.detailsApiResponse[0].faq) {
        // create a new dov container
        this.faqArray= [];
        var div = document.createElement('div');
        div.innerHTML = this.detailsApiResponse[0].faq;
        var faqAns: any = div.getElementsByClassName('faqfield-answer');
        var faqData = {
          question: '',
          answer: ''
        }
  
        for (const element of faqAns) {
          faqData = {
            answer: element.innerHTML,
            question: ''
          }
          this.faqArray.push(faqData);
        }
  
        //var div2 = document.createElement('div');
        //div.innerHTML = res[0].faq;
        var faqQues: any = div.getElementsByClassName('faqfield-question');
        var i = 0;
        for (const element1 of faqQues) {
          this.faqArray.forEach((element, index) => {
            if (i == index) {
              element.question = element1.innerHTML;
              exit;
            }
          });
          i++;
        }
  
        this.faqArray = this.faqArray.filter(data => data.answer != '');
        this.faqArrayLength = this.faqArray.length;

      }

     // var formattings = res[0].expected_time_frame_for_the_acti.split('-')[1];
      //this.dateFormating = new Date(formattings).toLocaleString('es-ES');
      //this.dateFormating = res[0].expected_time_frame_for_the_acti.split('-')[1];
      
      if (this.projectData.event_image_url == "") {
        this.projectData.event_image_url = this.staticLabels.static_default_image;
      }
      this.flag2 = true;
      // localStorage.removeItem('afterSuccessfullSSOLogin');
      // localStorage.removeItem('comingDirectFromSSOLogin');
      this.changeDetectorRef.detectChanges();
    }

  }
 labelApiResponse(data) {
        let appDetailsData =  data;
        this.staticLabels.activity_image = appDetailsData[0].activity_image;
        this.staticLabels.ask_info = appDetailsData[0].ask_info;
        this.staticLabels.back_to_main_page = appDetailsData[0].back_to_main_page;
        this.staticLabels.duration_image = appDetailsData[0].duration_image;
        this.staticLabels.healthcare_image = appDetailsData[0].healthcare_image;
        this.staticLabels.healthcare_text = appDetailsData[0].healthcare_text;
        this.staticLabels.improve_the_experience = appDetailsData[0].improve_the_experience;
        this.staticLabels.incentive_image = appDetailsData[0].incentive_image;
        this.staticLabels.language_images = appDetailsData[0].language_images;
        this.staticLabels.learn_about_roche_image = appDetailsData[0].learn_about_roche_image;
        this.staticLabels.learn_about_roche_text = appDetailsData[0].learn_about_roche_text;
        this.staticLabels.max_time_per_session = appDetailsData[0].max_time_per_session;
        this.staticLabels.more_spots_are_available = appDetailsData[0].more_spots_are_available;
        this.staticLabels.no_spots_are_available = appDetailsData[0].no_spots_are_available;
        this.staticLabels.one_spot_is_available = appDetailsData[0].one_spot_is_available;
        this.staticLabels.project_activity = appDetailsData[0].project_activity;
        this.staticLabels.project_duration = appDetailsData[0].project_duration;
        this.staticLabels.project_incentive = appDetailsData[0].project_incentive;
        this.staticLabels.project_language = appDetailsData[0].project_language;
        this.staticLabels.project_timeframe = appDetailsData[0].project_timeframe;
        this.staticLabels.register_button_label = appDetailsData[0].register_button_label;
        this.staticLabels.review_all_application = appDetailsData[0].review_all_application;
        this.staticLabels.roche_person_reach_you = appDetailsData[0].roche_person_reach_you;
        this.staticLabels.study_sessions = appDetailsData[0].study_sessions;
        this.staticLabels.time_frame_image = appDetailsData[0].time_frame_image;
        this.staticLabels.what_happen_after_you_apply = appDetailsData[0].what_happen_after_you_apply;
        this.staticLabels.your_voice_matters = appDetailsData[0].your_voice_matters;
        this.staticLabels.profiles = appDetailsData[0].profiles;
        this.staticLabels.profile_image = appDetailsData[0].profile_image;
        this.staticLabels.static_default_image = appDetailsData[0].static_default_image;
        this.staticLabels.about_the_co_creation = appDetailsData[0].about_the_co_creation;
        this.staticLabels.why_it_matters = appDetailsData[0].why_it_matters;
        this.staticLabels.what_you_will_get_by_joining = appDetailsData[0].what_you_will_get_by_joining;
        this.staticLabels.faq_title = appDetailsData[0].faq_title;
        this.staticLabels.footer_heading = appDetailsData[0].footer_heading;
        this.staticLabels.review_all_application_descripti = appDetailsData[0].review_all_application_descripti;


      if (this.projectData.event_image_url == "") {
      this.projectData.event_image_url = this.staticLabels.static_default_image;
      }
      this.flag1 = true;
      this.changeDetectorRef.detectChanges();
 }
  public redirect(projTitle: string, event_id: number): void {
    // this.router.navigate(['/registrationform']);
    this.router.navigate(['/registerform2'], { queryParams: { event_id: this.route.snapshot.queryParamMap.get('event_id'), title: projTitle } });
  }

  public redirectAskInfoForm(projTitle: string, event_id: number): void {
    //this.router.navigate(['/askinfogreeting']);
    //this.router.navigate(['/afterregistration']);
    //this.router.navigate(['/registerform2']);
    this.router.navigate(['/askinfoform'], { queryParams: { event_id: this.route.snapshot.queryParamMap.get('event_id'), title: projTitle } });
  }

}
